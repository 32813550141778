let baseUrl = ''; 
let baseImgPath;
let wsAddr = '';
let apiAddr='';
let Echokey = '';
let EchowsHost = '';
if (process.env.NODE_ENV == 'development') {
    // baseImgPath = '/imgs/';
    //st
    baseUrl = 'https://goboost-st.digitalsnail.cn/api'
    apiAddr = 'https://im.digitalsnail.cn/';
    wsAddr = "wss://wconnect.digitalsnail.cn/im/"
    Echokey = 987321;
    EchowsHost = 'goboost-st.digitalsnail.cn'

    //dev
    // baseUrl = 'https://goboost.digitalsnail.cn/api'
    // apiAddr = 'https://im.digitalsnail.cn/';
    // wsAddr = "wss://wconnect.digitalsnail.cn/im/"
    // Echokey = 987321;
    // EchowsHost = 'goboost-st.digitalsnail.cn'

    //prod
    // baseUrl = "https://api.goboost.cc/api"
    // apiAddr = "https://im.goboost.cc/api"
    // wsAddr = "wss://im.goboost.cc/msg_gateway"
    // Echokey = '123goboost';
    // EchowsHost = 'api.goboost.cc'

}else if(process.env.NODE_ENV == 'production'){
    baseImgPath = '';
    // baseUrl = "https://goboost-st.digitalsnail.cn/api";
    // apiAddr = 'https://im.digitalsnail.cn/';
    // wsAddr = "wss://wconnect.digitalsnail.cn/im/"

    //prod
    baseUrl = "https://api.goboost.cc/api"
    apiAddr = "https://im.goboost.cc/api"
    wsAddr = "wss://im.goboost.cc/msg_gateway"
    Echokey = '123goboost';
    EchowsHost = 'api.goboost.cc'

    //st
    // baseUrl = 'https://goboost-st.digitalsnail.cn/api'
    // apiAddr = 'https://im.digitalsnail.cn/';
    // wsAddr = "wss://wconnect.digitalsnail.cn/im/"
    // Echokey = 987321;
    // EchowsHost = 'goboost-st.digitalsnail.cn'
}
export {
    baseUrl,
    baseImgPath,
    wsAddr,
    apiAddr,
    Echokey,
    EchowsHost
}